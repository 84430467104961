
/deep/.el-dialog__body {
  display: flex;
  flex-direction: column;
}
/deep/.el-form {
  /*margin: 0 auto;*/
}
/deep/.el-dialog__header {
  background: #0200d8;
  .el-dialog__title {
    color: #ffffff;
    font-size: 16px;
  }
}
/deep/.el-button--primary {
  background: #0200d8;
  border-color: #0200d8;
}
/deep/.pages-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-top: 10px;
}
.competitionManagement {
  box-sizing: border-box;
  & * {
    box-sizing: border-box;
  }
  padding: 30px 30px 0px 30px;
  height: calc(100vh - 146px);
  .competitionManagement-header {
    display: flex;
    justify-content: space-between;
    .selectCompetition {
      color: #343442;
      font-size: 14px;
      font-weight: 500;
    }
    div {
      .creationBtn {
        /*width: 80px;*/
        height: 36px;
        color: #ffffff;
        background: #1222d8;
        border-color: #1222d8;
        line-height: 0;
        padding: 0 12px;
      }
    }
  }
  .competitionManagement-table {
    margin-top: 22px;
    width: 100%;
    height: calc(100% - 62px);
    .table-qu {
      height: calc(100% - 50px);
    }
  }
}
